import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/LoginModal.css';

function LoginModal({ onClose, onLoginSuccess, vendorSubUrl }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      setAuthToken(response.data.token);
      localStorage.setItem('token', response.data.token);
      
      if (response.data.user.role === 'customer') {
        onLoginSuccess(response.data.user);
        // Stay on the current page (vendor sub-URL)
      } else if (response.data.user.role === 'vendor') {
        navigate('/vendor/dashboard');
      }
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  const handleRegister = () => {
    navigate(`/register/${vendorSubUrl}`);
    onClose();
  };

  return (
    <div className="login-modal">
      <div className="login-modal-content">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
        <p className="register-cta">
          Not registered? <button onClick={handleRegister}>Register here</button>
        </p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}

export default LoginModal;