import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import axios from '../utils/axiosConfig';
import { logout, setAuthToken, isTokenValid } from '../utils/auth';
import '../styles/VendorDashboard.css';
import EmailCampaignModal from './EmailCampaignModal';
import GeneralDetails from './VendorTabs/GeneralDetails';
import CustomerSearch from './VendorTabs/CustomerSearch';
import CustomerProfile from './VendorTabs/CustomerProfile';
import SpecialsAndMenus from './VendorTabs/SpecialsAndMenus';
import BookingManagement from './VendorTabs/BookingManagement';
import AboutModal from './AboutModal';

function VendorDashboard() {
  const [activeTab, setActiveTab] = useState('generalDetails');
  const [programs, setPrograms] = useState([]);
  const [newProgram, setNewProgram] = useState({ name: '', description: '', stamps_required: '', about: '' });
  const [customers, setCustomers] = useState([]);
  const [redeemPin, setRedeemPin] = useState('');
  const [pushNotification, setPushNotification] = useState({ title: '', body: '' });
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailsSent, setEmailsSent] = useState(0);
  const [emailLimit, setEmailLimit] = useState(3000);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailSendResult, setEmailSendResult] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const [qrCodes, setQRCodes] = useState({});
  const [editingProgram, setEditingProgram] = useState(null);
  const [tempProgram, setTempProgram] = useState(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const debouncedFetchData = useCallback(
    debounce(() => {
      fetchPrograms();
      fetchCustomers();
      fetchEmailStats();
    }, 300),
    []
  );

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token || !isTokenValid()) {
        navigate('/login');
        return;
      }

      try {
        // Verify the token with the server
        await axios.get('/api/vendors/profile');
        
        // If authentication is successful, set the token and fetch data
        setAuthToken(token);
        debouncedFetchData();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Error verifying token:', error);
        }
      }
    };

    checkAuthAndFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [navigate, debouncedFetchData]);

  const fetchEmailStats = async () => {
    try {
      const response = await axios.get('/api/vendors/email-stats');
      setEmailsSent(response.data.emailsSent);
      setEmailLimit(response.data.emailLimit);
    } catch (error) {
      console.error('Error fetching email stats:', error);
    }
  };

  // const fetchPrograms = async () => {
  //   try {
  //     const response = await axios.get('/api/vendors/dashboard-programs');
  //     setPrograms(response.data);
  //   } catch (error) {
  //     console.error('Error fetching programs:', error);
  //   }
  // };

  const fetchPrograms = async () => {
    try {
      const response = await axios.get('/api/vendors/dashboard-programs');
      setPrograms(response.data.map(program => ({
        ...program,
        allow_qr_checkin: program.allow_qr_checkin || false // Ensure this field exists
      })));
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get('/api/vendors/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const toggleProgramActive = async (programId) => {
    try {
      await axios.put(`/api/vendors/programs/${programId}/toggle-active`);
      setPrograms(programs.map(program => 
        program.id === programId ? {...program, active: !program.active} : program
      ));
    } catch (error) {
      console.error('Error toggling program active status:', error);
    }
  };

  const handleCreateProgram = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/vendors/programs', newProgram);
      setNewProgram({ name: '', description: '', stamps_required: '', about: '' });
      fetchPrograms();
    } catch (error) {
      console.error('Error creating program:', error);
    }
  };

  const handleEditProgram = (program) => {
    setEditingProgram(program.id);
    setTempProgram({ ...program });
  };

  const handleUpdateProgram = async () => {
    try {
      await axios.put(`/api/vendors/programs/${tempProgram.id}`, tempProgram);
      setPrograms(programs.map(p => p.id === tempProgram.id ? tempProgram : p));
      setEditingProgram(null);
      setTempProgram(null);
    } catch (error) {
      console.error('Error updating program:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingProgram(null);
    setTempProgram(null);
  };

  const handleAboutClick = (program) => {
    setSelectedProgram(program);
    setShowAboutModal(true);
  };

  const handleUpdateAbout = async (updatedAbout) => {
    try {
      await axios.put(`/api/vendors/programs/${selectedProgram.id}`, {
        ...selectedProgram,
        about: updatedAbout
      });
      setPrograms(programs.map(p => 
        p.id === selectedProgram.id ? { ...p, about: updatedAbout } : p
      ));
      setShowAboutModal(false);
    } catch (error) {
      console.error('Error updating program about:', error);
    }
  };

  const handleRedeemStamp = async (customerId, programId) => {
    try {
      const response = await axios.post('/api/vendors/redeem-stamp', { customerId, programId, pin: redeemPin });
      alert(response.data.message);
      setRedeemPin('');
      fetchCustomers();
    } catch (error) {
      console.error('Error redeeming stamp:', error);
      alert(error.response?.data?.error || 'Error redeeming stamp');
    }
  };

  const handleRedeemVoucher = async (customerId, voucherId) => {
    try {
      const response = await axios.post('/api/vendors/redeem-voucher', { customerId, voucherId, pin: redeemPin });
      alert(response.data.message);
      setRedeemPin('');
      fetchCustomers();
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      alert(error.response?.data?.error || 'Error redeeming voucher');
    }
  };

  // const handleEmailCampaignSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('/api/vendors/send-campaign-email', emailCampaign);
  //     alert(`Campaign email sent successfully. Success: ${response.data.successCount}, Errors: ${response.data.errorCount}`);
  //     setEmailCampaign({ subject: '', templateString: '', data: {} });
  //   } catch (error) {
  //     console.error('Error sending campaign email:', error);
  //     alert('Failed to send campaign email');
  //   }
  // };

  const handlePushNotificationSubmit = async (e) => {
    e.preventDefault();
    setNotificationStatus(null); // Reset status before sending
    try {
      const response = await axios.post('/api/vendors/send-push-notification', {
        title: pushNotification.title,
        body: pushNotification.body
      });
      console.log('Push notification response:', response.data);
      setNotificationStatus({
        success: true,
        message: `Push notifications sent successfully. Success: ${response.data.successCount}, Errors: ${response.data.errorCount}`
      });
      setPushNotification({ title: '', body: '' });
    } catch (error) {
      console.error('Error sending push notifications:', error);
      setNotificationStatus({
        success: false,
        message: `Failed to send push notifications: ${error.response?.data?.error || error.message}`
      });
    }
  };

  const handleEmailCampaignSubmit = async (emailContent, subject) => {
    setIsEmailModalOpen(false);
    setIsSendingEmail(true);
    try {
      const response = await axios.post('/api/vendors/send-campaign-email', {
        subject: subject,
        templateString: emailContent,
        data: {}
      });
      setEmailsSent(response.data.totalEmailsSent);
      setEmailSendResult({
        success: true,
        message: `Campaign email sent successfully. Success: ${response.data.successCount}, Errors: ${response.data.errorCount}`
      });
    } catch (error) {
      console.error('Error sending campaign email:', error);
      setEmailSendResult({
        success: false,
        message: error.response?.data?.error || 'Failed to send campaign email'
      });
    } finally {
      setIsSendingEmail(false);
    }
  };

  const handleRequestIncrease = () => {
    window.location.href = `mailto:duane@orderlocal.co.za?subject=Request%20Email%20Limit%20Increase&body=Current%20limit:%20${emailLimit}%0D%0AEmails%20sent:%20${emailsSent}`;
  };

  const toggleQRCheckin = async (programId) => {
    try {
        const response = await axios.put(`/api/vendors/programs/${programId}/toggle-qr-checkin`);
        setPrograms(programs.map(program => 
            program.id === programId ? {...program, allow_qr_checkin: response.data.allow_qr_checkin} : program
        ));
    } catch (error) {
        console.error('Error toggling QR check-in:', error);
    }
};

const generateQRCode = async (programId) => {
    try {
        const response = await axios.get(`/api/vendors/programs/${programId}/qr-code`);
        setQRCodes(prevCodes => ({...prevCodes, [programId]: response.data.qrCode}));
    } catch (error) {
        console.error('Error generating QR code:', error);
    }
};

  const renderTabContent = () => {
    switch(activeTab) {
      case 'generalDetails':
        return <GeneralDetails />;
        case 'loyaltyPrograms':
          return (
            <div>
              <h2>Create New Loyalty Program</h2>
              <form className="create-program-form" onSubmit={handleCreateProgram}>
                <input
                  type="text"
                  placeholder="Program Name"
                  value={newProgram.name}
                  onChange={(e) => setNewProgram({...newProgram, name: e.target.value})}
                  required
                />
                <input
                  type="text"
                  placeholder="What is the reward?"
                  value={newProgram.description}
                  onChange={(e) => setNewProgram({...newProgram, description: e.target.value})}
                  required
                />
                <input
                  type="number"
                  placeholder="Stamps Required"
                  value={newProgram.stamps_required}
                  onChange={(e) => setNewProgram({...newProgram, stamps_required: e.target.value})}
                  required
                />
                <textarea
                  placeholder="About the Program"
                  value={newProgram.about}
                  onChange={(e) => setNewProgram({...newProgram, about: e.target.value.substring(0, 1000)})}
                  maxLength={1000}
                />
                <p>{newProgram.about.length}/1000 characters</p>
                <button type="submit">Create Program</button>
              </form>
        
             
            <h2>Your Loyalty Programs</h2>
            <table className="loyalty-programs-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Stamps Required</th>
                  <th>Reward</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>QR Code</th>
                </tr>
              </thead>
              <tbody>
                {programs.map(program => (
                  <tr key={program.id}>
                    <td>
                      {editingProgram === program.id ? (
                        <input
                          type="text"
                          value={tempProgram.name}
                          onChange={(e) => setTempProgram({...tempProgram, name: e.target.value})}
                        />
                      ) : (
                        program.name
                      )}
                    </td>
                    <td>{program.stamps_required}</td>
                    <td>
                      {editingProgram === program.id ? (
                        <input
                          type="text"
                          value={tempProgram.description}
                          onChange={(e) => setTempProgram({...tempProgram, description: e.target.value})}
                        />
                      ) : (
                        program.description
                      )}
                    </td>
                    <td>{program.active ? 'Active' : 'Inactive'}</td>
                    <td>
                      <div className="button-group">
                        {editingProgram === program.id ? (
                          <>
                            <button onClick={handleUpdateProgram}>Update</button>
                            <button onClick={handleCancelEdit}>Cancel</button>
                          </>
                        ) : (
                          <>
                            <button onClick={() => handleEditProgram(program)}>Edit</button>
                            <button onClick={() => handleAboutClick(program)}>About</button>
                            <button 
                              className={`btn ${program.active ? 'btn-deactivate' : 'btn-activate'}`}
                              onClick={() => toggleProgramActive(program.id)}
                            >
                              {program.active ? 'Deactivate' : 'Activate'}
                            </button>
                            <button 
                              className="btn btn-qr-toggle"
                              onClick={() => toggleQRCheckin(program.id)}
                            >
                              {program.allow_qr_checkin ? 'Disable QR Check-in' : 'Enable QR Check-in'}
                            </button>
                            {program.allow_qr_checkin && (
                              <button 
                                className="btn btn-generate-qr"
                                onClick={() => generateQRCode(program.id)}
                              >
                                Generate QR Code
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      {program.allow_qr_checkin && qrCodes[program.id] && (
                        <div className="qr-code-container">
                          <img src={qrCodes[program.id]} alt="QR Code" className="qr-code-image" />
                          <a href={qrCodes[program.id]} download={`qr-code-${program.name}.png`}>Download QR Code</a>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showAboutModal && selectedProgram && (
              <AboutModal
                program={selectedProgram}
                onClose={() => setShowAboutModal(false)}
                onUpdate={handleUpdateAbout}
              />
            )}
          </div>
        );
      case 'customerRedemption':
        return (
          <div>
            <h2>Customer Redemption</h2>
            <CustomerSearch onSelectCustomer={setSelectedCustomer} />
          {selectedCustomer && (
            <CustomerProfile 
              customerId={selectedCustomer.id} 
              onClose={() => setSelectedCustomer(null)}
            />
            )}
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Program</th>
                  <th>Stamps</th>
                  <th>Cards Completed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customers.flatMap(customer =>
                  customer.enrollments.map(enrollment => (
                    <tr key={`${customer.id}-${enrollment.program_id}`}>
                      <td>{customer.full_name}</td>
                      <td>{enrollment.program_name}</td>
                      <td>{enrollment.stamps} / {enrollment.stamps_required}</td>
                      <td>{enrollment.cards_completed || 0}</td>
                      <td>
                        {/* <input
                          type="password"
                          placeholder="Enter PIN"
                          value={redeemPin}
                          onChange={(e) => setRedeemPin(e.target.value)}
                          className="redeem-input"
                        /> */}
                        <button className="redeem-btn" onClick={() => handleRedeemStamp(customer.id, enrollment.program_id)}>
                          Redeem Stamp
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <h2>Vouchers</h2>
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Program</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customers.flatMap(customer =>
                  (customer.vouchers || []).map(voucher => (
                    <tr key={`${customer.id}-${voucher.id}`}>
                      <td>{customer.full_name}</td>
                      <td>{voucher.program_name}</td>
                      <td>{voucher.redeemed ? 'Redeemed' : 'Available'}</td>
                      <td>{new Date(voucher.created_at).toLocaleString()}</td>
                      <td>
                        {!voucher.redeemed ? (
                          <>
                            {/* <input
                              type="password"
                              placeholder="Enter PIN"
                              value={redeemPin}
                              onChange={(e) => setRedeemPin(e.target.value)}
                              className="redeem-input"
                            /> */}
                            <button className="redeem-btn" onClick={() => handleRedeemVoucher(customer.id, voucher.id)}>
                              Redeem Voucher
                            </button>
                          </>
                        ) : (
                          `Redeemed on ${new Date(voucher.redeemed_at).toLocaleString()}`
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        );
      case 'campaigns':
        return (
          <div className="communication-section">
            <div className="email-campaign-section">
              <h2>Email Campaign</h2>
              <p>Emails sent: {emailsSent} / {emailLimit}</p>
              <button 
                onClick={() => setIsEmailModalOpen(true)} 
                className="create-campaign-btn"
                disabled={isSendingEmail || emailsSent >= emailLimit}
              >
                Create Email Campaign
              </button>
              {isSendingEmail && <p>Sending email campaign...</p>}
              {emailsSent >= emailLimit && (
                <button onClick={handleRequestIncrease} className="request-increase-btn">
                  Request Limit Increase
                </button>
              )}
              <EmailCampaignModal
                isOpen={isEmailModalOpen}
                onClose={() => setIsEmailModalOpen(false)}
                onSubmit={handleEmailCampaignSubmit}
                isSending={isSendingEmail}
                sendResult={emailSendResult}
              />
              {emailSendResult && (
                <p className={`send-result ${emailSendResult.success ? 'success' : 'error'}`}>
                  {emailSendResult.message}
                </p>
              )}
            </div>

            <div className="push-notification-section">
              <h2>Send Push Notification</h2>
              <form onSubmit={handlePushNotificationSubmit}>
                <input
                  type="text"
                  placeholder="Notification Title"
                  value={pushNotification.title}
                  onChange={(e) => setPushNotification({...pushNotification, title: e.target.value})}
                  required
                />
                <textarea
                  placeholder="Notification Body"
                  value={pushNotification.body}
                  onChange={(e) => setPushNotification({...pushNotification, body: e.target.value})}
                  required
                />
                <button type="submit">Send Push Notification</button>
              </form>
              {notificationStatus && (
                <p style={{ color: notificationStatus.success ? 'green' : 'red' }}>
                  {notificationStatus.message}
                </p>
              )}
            </div>
          </div>
        );
      case 'specialsAndMenus':
          return <SpecialsAndMenus />;
      case 'bookingRequests':
          return <BookingManagement />;
      default:
        return <GeneralDetails />;
    }
  };

  return (
    <div className="vendor-dashboard">
      <div className="sidebar">
        <button onClick={() => setActiveTab('generalDetails')} className={activeTab === 'generalDetails' ? 'active' : ''}>General Details</button>
        <button onClick={() => setActiveTab('loyaltyPrograms')} className={activeTab === 'loyaltyPrograms' ? 'active' : ''}>Loyalty Programs</button>
        <button onClick={() => setActiveTab('customerRedemption')} className={activeTab === 'customerRedemption' ? 'active' : ''}>Customer Redemption</button>
        <button onClick={() => setActiveTab('campaigns')} className={activeTab === 'campaigns' ? 'active' : ''}>Campaigns</button>
        <button onClick={() => setActiveTab('specialsAndMenus')} className={activeTab === 'specialsAndMenus' ? 'active' : ''}>Specials & Menus</button>
        <button onClick={() => setActiveTab('bookingRequests')} className={activeTab === 'bookingRequests' ? 'active' : ''}>Booking Requests</button>
      </div>
      <div className="tab-content">
        <h1>Vendor Dashboard</h1>
        <button className="logout-btn" onClick={logout}>Logout</button>
        {renderTabContent()}
      </div>
    </div>
  );
}

export default VendorDashboard;