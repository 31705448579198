import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isTokenValid } from '../utils/auth';

function ProtectedRoute({ children }) {
  if (!isTokenValid()) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;