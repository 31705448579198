import React, { useState } from 'react';
import axios from '../utils/axiosConfig';
import '../styles/EnquiryForm.css';

export default function EnquiryForm({ onClose }) {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [notification, setNotification] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/enquiry/submit', formData);
      
      if (response.status === 200) {
        setNotification({ type: 'success', message: 'Thank you for your enquiry. We will get back to you soon.' });
        setTimeout(() => {
          setNotification(null);
          onClose();
        }, 3000);
      } else {
        throw new Error('Failed to send enquiry');
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({ type: 'error', message: error.message || 'Failed to send enquiry. Please try again later.' });
    }
  };

  return (
<div className="enquiry-form-overlay">
  <div className="enquiry-form-container">
    <button className="close-btn" onClick={onClose}>&times;</button>
    <h2>Enquire Now</h2>
    {notification && (
      <div className={`notification ${notification.type}`}>
        {notification.message}
      </div>
    )}
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Your Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <textarea
        name="message"
        placeholder="Your Message"
        value={formData.message}
        onChange={handleChange}
        required
      ></textarea>
      <button type="submit">Send Enquiry</button>
    </form>
  </div>
</div>

  );
}