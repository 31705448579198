import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import debounce from 'lodash.debounce';
import '../../styles/SpecialsAndMenus.css';

function SpecialsAndMenus() {
    const [specials, setSpecials] = useState([]);
    const [menus, setMenus] = useState([]);
    const [newSpecial, setNewSpecial] = useState({
      name: '',
      description: '',
      value: '',
      image: null,
      weblink: '',
    });
    const [newMenu, setNewMenu] = useState({
      pdfFile: null,
      weblink: '',
    });
    const [editingSpecial, setEditingSpecial] = useState(null);
    const [editingMenu, setEditingMenu] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchSpecials = useCallback(async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/vendors/specials?page=${page}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSpecials(prev => page === 1 ? response.data.specials : [...prev, ...response.data.specials]);
        setHasMore(response.data.hasMore);
      } catch (error) {
        console.error('Error fetching specials:', error);
        setError('Failed to fetch specials. Please try again.');
        setSpecials([]);
      }
    }, [page]);

    const fetchMenus = useCallback(async () => {
      try {
        console.log('Fetching menus...');
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        
        const response = await axios.get('/api/vendors/menus', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        console.log('Menus response:', response.data);
        setMenus(response.data);
      } catch (error) {
        console.error('Error fetching menus:', error.response?.data || error.message);
        console.error('Full error object:', error);
        setError('Failed to fetch menus. Please try again.');
        setMenus([]);
      }
    }, []);

    // const debouncedFetchData = useCallback(
    //   debounce(() => {
    //     fetchSpecials();
    //     fetchMenus();
    //   }, 300),
    //   [fetchSpecials, fetchMenus]
    // );

    const debouncedFetchData = useCallback(
      debounce(() => {
        setIsLoading(true);
        Promise.all([fetchSpecials(), fetchMenus()])
          .catch(error => console.error('Error fetching data:', error))
          .finally(() => setIsLoading(false));
      }, 300),
      [fetchSpecials, fetchMenus]
    );

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
      }
      debouncedFetchData();

      return () => {
        debouncedFetchData.cancel();
      };
    }, [debouncedFetchData]);

    const handleSpecialChange = (e) => {
      const { name, value, files } = e.target;
      setNewSpecial(prev => ({
        ...prev,
        [name]: files ? files[0] : value
      }));
    };

    const handleMenuChange = (e) => {
      const { name, value, files } = e.target;
      setNewMenu(prev => ({
        ...prev,
        [name]: files ? files[0] : value
      }));
    };

    const handleSpecialSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      Object.keys(newSpecial).forEach(key => {
        if (newSpecial[key]) {
          formData.append(key, newSpecial[key]);
        }
      });

      try {
        if (editingSpecial) {
          await axios.put(`/api/vendors/specials/${editingSpecial.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setSuccess('Special updated successfully');
        } else {
          await axios.post('/api/vendors/specials', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setSuccess('Special created successfully');
        }
        setNewSpecial({ name: '', description: '', value: '', image: null, weblink: '' });
        setEditingSpecial(null);
        setPage(1);
        fetchSpecials();
      } catch (error) {
        console.error('Error submitting special:', error);
        setError('Failed to submit special. Please try again.');
      }
    };

    const handleMenuSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      if (newMenu.pdfFile) formData.append('pdfFile', newMenu.pdfFile);
      if (newMenu.weblink) formData.append('weblink', newMenu.weblink);
    
      try {
        let response;
        if (editingMenu) {
          response = await axios.put(`/api/vendors/menus/${editingMenu.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
        } else {
          response = await axios.post('/api/vendors/menus', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
        }
        setSuccess(editingMenu ? 'Menu updated successfully' : 'Menu created successfully');
        setNewMenu({ pdfFile: null, weblink: '' });
        setEditingMenu(null);
        fetchMenus();
      } catch (error) {
        console.error('Error submitting menu:', error);
        const errorMessage = error.response?.data?.error || 'Failed to submit menu. Please try again.';
        setError(errorMessage);
      }
    };

    const handleDeleteSpecial = async (id) => {
      try {
        await axios.delete(`/api/vendors/specials/${id}`);
        setSuccess('Special deleted successfully');
        setPage(1);
        fetchSpecials();
      } catch (error) {
        console.error('Error deleting special:', error);
        setError('Failed to delete special. Please try again.');
      }
    };

    const handleDeleteMenu = async (id) => {
      try {
        await axios.delete(`/api/vendors/menus/${id}`);
        setSuccess('Menu deleted successfully');
        fetchMenus();
      } catch (error) {
        console.error('Error deleting menu:', error);
        setError('Failed to delete menu. Please try again.');
      }
    };

    const loadMoreSpecials = () => {
      setPage(prevPage => prevPage + 1);
    };

    if (isLoading) {
      return <div className="loader">Loading menus and specials...</div>;
    }

    return (
      <div className="specials-and-menus">
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        <section className="specials-section">
          <h2>Specials</h2>
          <form onSubmit={handleSpecialSubmit}>
            <input
              type="text"
              name="name"
              value={newSpecial.name}
              onChange={handleSpecialChange}
              placeholder="Special Name"
              required
            />
            <textarea
              name="description"
              value={newSpecial.description}
              onChange={handleSpecialChange}
              placeholder="Description"
            />
            <input
              type="number"
              name="value"
              value={newSpecial.value}
              onChange={handleSpecialChange}
              placeholder="Value in Rand"
            />
            <input
              type="file"
              name="image"
              onChange={handleSpecialChange}
              accept="image/*"
            />
            <input
              type="url"
              name="weblink"
              value={newSpecial.weblink}
              onChange={handleSpecialChange}
              placeholder="Weblink"
            />
            <button type="submit" className="submit-btn">{editingSpecial ? 'Update' : 'Add'} Special</button>
          </form>
          <div className="specials-list">
            {specials.map(special => (
              <div key={special.id} className="special-item">
                <h3>{special.name}</h3>
                <p>{special.description}</p>
                {special.value && <p>R{special.value}</p>}
                {special.image_url && <img src={special.image_url} alt={special.name} />}
                {special.weblink && <a href={special.weblink} target="_blank" rel="noopener noreferrer">More Info</a>}
                <div className="button-group">
                <button onClick={() => setEditingSpecial(special)}>Edit</button>
                <button onClick={() => handleDeleteSpecial(special.id)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
          {hasMore && <button onClick={loadMoreSpecials}>Load More</button>}
        </section>

        <section className="menus-section">
        <h2>Menus</h2>
        <form onSubmit={handleMenuSubmit}>
          <div className="menu-input-group">
            <label>
              Upload PDF Menu:
              <input
                type="file"
                name="pdfFile"
                onChange={handleMenuChange}
                accept="application/pdf"
              />
            </label>
          </div>
          <div className="menu-input-group">
            <label>
              OR Enter Menu Weblink:
              <input
                type="url"
                name="weblink"
                value={newMenu.weblink}
                onChange={handleMenuChange}
                placeholder="https://example.com/menu"
              />
            </label>
          </div>
          <p className="menu-input-help">You can either upload a PDF file or provide a weblink to your menu.</p>
          
          <button type="submit" className="submit-btn">{editingMenu ? 'Update' : 'Upload'} Menu</button>
        </form>
        <div className="menus-list">
          {menus.map(menu => (
            <div key={menu.id} className="menu-item">
              {menu.pdf_url && (
                <>
                  <a href={menu.pdf_url} target="_blank" rel="noopener noreferrer" className="pdf-link">
                    View PDF Menu
                  </a>
                  {menu.thumbnail_url && (
                    <img src={menu.thumbnail_url} alt="Menu thumbnail" className="menu-thumbnail" />
                  )}
                </>
              )}
              {menu.weblink && (
                <a href={menu.weblink} target="_blank" rel="noopener noreferrer" className="weblink">
                  View Menu Online
                </a>
              )}
                <div className="button-group">
              <button onClick={() => setEditingMenu(menu)}>Edit</button>
              <button onClick={() => handleDeleteMenu(menu.id)}>Delete</button>
            </div>
            </div>
          ))}
        </div>
        </section>
      </div>
    );
}

export default SpecialsAndMenus;