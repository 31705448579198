import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import '../../styles/GeneralDetails.css';


function GeneralDetails() {
  const [vendorDetails, setVendorDetails] = useState({
    business_name: '',
    phone: '',
    whatsapp_number: '',
    address: '',
    logo_url: '',
    cover_url: ''
  });
  const [slides, setSlides] = useState([]);
  // const [newSlide, setNewSlide] = useState(null);
 
  const [isLoading, setIsLoading] = useState(true);

  const debouncedFetchData = useCallback(
    debounce(async () => {
      setIsLoading(true);
      try {
        await fetchVendorDetails();
        await fetchVendorSlides();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // const debouncedFetchData = useCallback(
  //   debounce(async () => {
  //     try {
  //       await fetchVendorDetails();
  //       await fetchVendorSlides();
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }, 300),
  //   []
  // );

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    
    debouncedFetchData();
    
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const fetchVendorDetails = async () => {
    try {
      const response = await axios.get('/api/vendors/profile');
      setVendorDetails(prevDetails => ({
        ...prevDetails,
        ...response.data,
        logo_url: response.data.logo_url || '',
        cover_url: response.data.cover_url || ''
      }));
    } catch (error) {
      console.error('Error fetching vendor details:', error);
    }
  };

  const fetchVendorSlides = async () => {
    try {
      console.log('Fetching vendor slides...');
      const response = await axios.get('/api/vendors/slides');
      console.log('Vendor slides response:', response.data);
      if (Array.isArray(response.data)) {
        setSlides(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setSlides([]);
      }
    } catch (error) {
      console.error('Error fetching vendor slides:', error.response?.data || error.message);
      setSlides([]);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   if (files[0]) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setVendorDetails(prevDetails => ({
  //         ...prevDetails,
  //         [name]: reader.result
  //       }));
  //     };
  //     reader.readAsDataURL(files[0]);
  //   }
  // };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setVendorDetails(prevDetails => ({
        ...prevDetails,
        [name]: files[0]
      }));
    }
  };

  // const handleSlideChange = (e) => {
  //   setNewSlide(e.target.files[0]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    Object.keys(vendorDetails).forEach(key => {
      if (vendorDetails[key] !== null && vendorDetails[key] !== undefined) {
        if (key === 'logo_url' || key === 'cover_url') {
          if (vendorDetails[key] instanceof File) {
            formData.append(key, vendorDetails[key]);
          }
        } else {
          formData.append(key, vendorDetails[key]);
        }
      }
    });
  
    console.log('Form data being sent:', Object.fromEntries(formData));
  
    try {
      const response = await axios.put('/api/vendors/profile', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('Server response:', response.data);
      setVendorDetails(prevDetails => ({
        ...prevDetails,
        ...response.data
      }));
      alert('Vendor details updated successfully');
    } catch (error) {
      console.error('Error updating vendor details:', error.response ? error.response.data : error);
      alert('Error updating vendor details: ' + (error.response ? error.response.data.error : error.message));
    }
  };

  // const dataURLtoFile = (dataurl, filename) => {
  //   let arr = dataurl.split(','),
  //       mime = arr[0].match(/:(.*?);/)[1],
  //       bstr = atob(arr[1]), 
  //       n = bstr.length, 
  //       u8arr = new Uint8Array(n);
  //   while(n--){
  //       u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, {type:mime});
  // }


  const handleAddSlide = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
  
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await axios.post('/api/vendors/slides', formData, {
          headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('New slide added:', response.data);
        await fetchVendorSlides(); // Refresh slides after adding
        alert('Slide added successfully');
      } catch (error) {
        console.error('Error adding slide:', error);
        alert('Error adding slide: ' + (error.response?.data?.error || error.message));
      } finally {
        input.remove(); // Clean up input element
      }
    };
    input.click();
  };
  

  const handleDeleteSlide = async (slideId) => {
    try {
      await axios.delete(`/api/vendors/slides/${slideId}`);
      setSlides(prevSlides => prevSlides.filter(slide => slide.id !== slideId));
      alert('Slide deleted successfully');
    } catch (error) {
      console.error('Error deleting slide:', error);
      alert('Error deleting slide: ' + (error.response?.data?.error || error.message));
    }
  };

  // const handleMoveSlide = async (index, direction) => {
  //   const newSlides = [...slides];
  //   const [removed] = newSlides.splice(index, 1);
  //   const newIndex = direction === 'up' ? index - 1 : index + 1;
  //   newSlides.splice(newIndex, 0, removed);
  //   setSlides(newSlides);
  
  //   try {
  //     await axios.put('/api/vendors/slides/reorder', { slideOrder: newSlides.map(slide => slide.id) });
  //   } catch (error) {
  //     console.error('Error reordering slides:', error);
  //     // Optionally, you can revert the state if the server update fails
  //     // setSlides(slides);
  //   }
  // };

  // const onDragEnd = async (result) => {
  //   if (!result.destination) {
  //     return;
  //   }
  
  //   const newSlides = Array.from(slides);
  //   const [reorderedItem] = newSlides.splice(result.source.index, 1);
  //   newSlides.splice(result.destination.index, 0, reorderedItem);
  
  //   setSlides(newSlides);
  
  //   try {
  //     await axios.put('/api/vendors/slides/reorder', { slideOrder: newSlides.map(slide => slide.id) });
  //   } catch (error) {
  //     console.error('Error reordering slides:', error);
  //     // Optionally, you can revert the state if the server update fails
  //     // setSlides(slides);
  //   }
  // };

  // const onCropComplete = (crop, imageKey) => {
  //   if (imageRef && crop.width && crop.height) {
  //     const croppedImageUrl = getCroppedImg(
  //       imageRef,
  //       crop,
  //       'cropped.jpg'
  //     );
  //     setVendorDetails(prev => ({ ...prev, [imageKey]: croppedImageUrl }));
  //   }
  // };
  
  // const getCroppedImg = (image, crop, fileName) => {
  //   const canvas = document.createElement('canvas');
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   canvas.width = crop.width;
  //   canvas.height = crop.height;
  //   const ctx = canvas.getContext('2d');
  
  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );
  
  //   return canvas.toDataURL('image/jpeg');
  // };

  if (isLoading) {
    return <div className="loader">Loading general details...</div>;
  }
  
  return (
    <div className="general-details">
      <h2>General Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="business_name">Business Name:</label>
          <input
            type="text"
            id="business_name"
            name="business_name"
            value={vendorDetails.business_name || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={vendorDetails.phone || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="whatsapp_number">WhatsApp Number:</label>
          <input
            type="tel"
            id="whatsapp_number"
            name="whatsapp_number"
            value={vendorDetails.whatsapp_number || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <textarea
            id="address"
            name="address"
            value={vendorDetails.address || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="logo_url">Logo:</label>
          <input
            type="file"
            id="logo_url"
            name="logo_url"
            onChange={handleFileChange}
            accept="image/*"
          />
          {vendorDetails.logo_url && (
            <img src={typeof vendorDetails.logo_url === 'string' ? vendorDetails.logo_url : URL.createObjectURL(vendorDetails.logo_url)} alt="Logo" className="preview-image" />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="cover_url">Cover Image:</label>
          <input
            type="file"
            id="cover_url"
            name="cover_url"
            onChange={handleFileChange}
            accept="image/*"
          />
          {vendorDetails.cover_url && (
            <img src={typeof vendorDetails.cover_url === 'string' ? vendorDetails.cover_url : URL.createObjectURL(vendorDetails.cover_url)} alt="Cover" className="preview-image" />
          )}
        </div>
        <button type="submit" className="submit-btn">Update Details</button>
      </form>

      <div className="image-slides">
      <h3>Image Slides</h3>
      <button onClick={handleAddSlide} className="add-slide-btn">Add Slide</button>
      <div className="slides-container">
        {slides.map((slide, index) => (
          <div key={slide.id} className="slide-item">
            <img src={slide.image_url} alt={`Slide ${index + 1}`} />
            <div className="slide-controls">
              <button onClick={() => handleDeleteSlide(slide.id)} className="delete-slide-btn">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>

    </div>
  );
}

export default GeneralDetails;