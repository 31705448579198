import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';
import EnquiryForm from './EnquiryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LandingPage() {
    const [showEnquiryForm, setShowEnquiryForm] = useState(false);


  return (
    <body id="lp1">
    <div className="landing-page full-width">

      <div className="landing-content">
        <svg className="landing-logo" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" fill="#4285F4" />
          <path d="M30 50 L50 70 L70 30" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        
        <h1 className="landing-title">
          <span className="text-blue">Boost customer loyalty</span>
          <br />
          <span className="text-dark">in minutes</span>
        </h1>
        
        <p className="landing-description">
          Empower your business with our easy-to-use loyalty program solution.
        </p>
        
        {/* <Link to="/register" className="cta-button">
          Get Started Free
        </Link> */}

            

                <a href="https://wa.me/27817501758" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
                <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                <span className="whatsapp-text"> Chat with us</span>
                </a>

        <Link onClick={() => setShowEnquiryForm(true)} className="cta-button">
        Enquire Now
      </Link>
      
      {showEnquiryForm && <EnquiryForm onClose={() => setShowEnquiryForm(false)} />}
        
        <p className="landing-footnote">
          Start rewarding your customers today.
        </p>
      </div>

      <section className="features-section">
        <h2>Powerful Features</h2>
        <div className="features-grid">
          {[
            { icon: "🎁", title: "Customizable Programs", desc: "Create unique loyalty programs tailored to your business needs" },
            { icon: "💬", title: "Customer Engagement", desc: "Engage customers with email campaigns and push notifications" },
            { icon: "🛡️", title: "White Label Solution", desc: "Offer a seamless branded experience to your customers" }
          ].map((feature, index) => (
            <div key={index} className="feature-card">
              <span className="feature-icon">{feature.icon}</span>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="brand-image-section">
        <svg className="brand-image" width="200" height="100" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 80 C20 80 50 20 100 50 C150 80 180 20 180 20" stroke="#4285F4" strokeWidth="4" strokeLinecap="round"/>
          <circle cx="20" cy="80" r="8" fill="#4285F4"/>
          <circle cx="100" cy="50" r="8" fill="#4285F4"/>
          <circle cx="180" cy="20" r="8" fill="#4285F4"/>
        </svg>
      </section>

      <section className="benefits-section">
  <h2>Benefits for Your Business</h2>
  <p>Loyalty and more...</p>
  <div className="benefits-grid">
    {[
      { icon: 'users', title: 'Customer satisfaction', desc: 'Increase customer retention and loyalty' },
      { icon: 'chart-line', title: 'Bottom line', desc: 'Boost sales and revenue' },
      { icon: 'gamepad', title: 'Understand your customer better', desc: 'Gain valuable customer insights' },
      { icon: 'plug', title: 'Control', desc: 'Enhance brand reputation' },
      // Add the fifth benefit if needed
    ].map((benefit, index) => (
      <div key={index} className="benefit-item">
        <div className="benefit-icon">
          <FontAwesomeIcon icon={benefit.icon} />
        </div>
        <h3>{benefit.title}</h3>
        <p>{benefit.desc}</p>
      </div>
    ))}
  </div>
</section>
                

        <section className="cta-section">
        <h2>Ready to Grow Your Business?</h2>
        {/* <p>Join thousands of businesses already boosting their customer loyalty</p> */}
        <div className="cta-buttons">
            <button onClick={() => setShowEnquiryForm(true)} className="cta-button cta-button-secondary">
            Request More Info
            </button>
        </div>
        </section>

      <footer className="landing-footer">
        <p>&copy; 2024 My Local. All rights reserved.</p>
      </footer>
    </div>
    </body>
  );
}