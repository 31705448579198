import React, { useState, useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/LoyaltyCard.css';

function LoyaltyCard({ program: propProgram }) {
  const [program, setProgram] = useState(propProgram || null);
  const { programId } = useParams();
  const [lastRedeemedStamp, setLastRedeemedStamp] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    
    if (!propProgram && programId) {
      fetchProgramData();
    }
  }, [propProgram, programId]);

  useEffect(() => {
    if (lastRedeemedStamp !== null) {
      setTimeout(() => setLastRedeemedStamp(null), 1000);
    }
  }, [lastRedeemedStamp]);

  useEffect(() => {
    if (showScanner && videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        result => handleScan(result.data),
        {
          onDecodeError: (error) => {
            console.error('QR code decode error:', error);
          },
          maxScansPerSecond: 1,
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );
      setQrScanner(scanner);
      scanner.start();
    }
    return () => {
      if (qrScanner) {
        qrScanner.stop();
      }
    };
  }, [showScanner]);

  const fetchProgramData = async () => {
    try {
      const response = await axios.get(`/api/customers/programs/${programId}`);
      setProgram(response.data);
    } catch (error) {
      console.error('Error fetching program data:', error);
    }
  };

  const handleScan = async (data) => {
    if (data) {
      try {
        console.log('Scanned data:', data); // For debugging
        const qrData = JSON.parse(data);
        console.log('Parsed QR data:', qrData); // For debugging
        console.log('Current programId:', programId); // For debugging
        if (qrData.type === 'visit_checkin' && qrData.programId === parseInt(programId)) {
          const response = await axios.post('/api/customers/redeem-visit', { programId: qrData.programId });
          console.log('Redeem visit response:', response.data); // For debugging
          alert(response.data.message);
          setShowScanner(false);
          fetchProgramData();
        } else {
          console.log('Invalid QR code: Mismatch in programId or type'); // For debugging
          alert('Invalid QR code');
        }
      } catch (error) {
        console.error('Error processing QR code:', error);
        if (error.response) {
          console.error('Server response:', error.response.data); // For debugging
        }
        alert('Error processing QR code: ' + (error.response?.data?.error || error.message));
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
  };

  if (!program) return <div>Loading...</div>;

  return (
    <div className="loyalty-card-page">
      <div className="loyalty-card-header">
        <button onClick={() => navigate(-1)} className="back-button">← Back</button>
        <button onClick={() => setShowScanner(true)} className="redeem-visit-button">Redeem Visit</button>
      </div>
      {showScanner && (
        <div className="qr-scanner-container">
          <video ref={videoRef} style={{ width: '100%' }} />
          <button onClick={() => {
            setShowScanner(false);
            if (qrScanner) {
              qrScanner.stop();
            }
          }}>Close Scanner</button>
        </div>
      )}
      <div className="loyalty-card">
        <h3 className="program-name">{program.name}</h3>
        <div className="stamps-container">
          {[...Array(parseInt(program.stamps_required))].map((_, index) => (
            <div
              key={index}
              className={`stamp ${index < program.stamps ? 'active' : ''} ${index === lastRedeemedStamp ? 'redeemed' : ''}`}
            >
              🥩
            </div>
          ))}
        </div>
        <p className="stamp-count">Stamps: {program.stamps}/{program.stamps_required}</p>
        <p className="cards-completed">Cards Completed: {program.cards_completed || 0}</p>
        <button onClick={() => setShowAbout(!showAbout)} className="about-button">
          {showAbout ? 'Hide Details' : 'Show Details'}
        </button>
        {showAbout && (
          <div className="about-section">
            <p>{program.about || 'No additional information available.'}</p>
          </div>
        )}
        <h4 className="vouchers-title">Vouchers</h4>
        <div className="vouchers-container">
          {program.vouchers && program.vouchers.length > 0 ? (
            program.vouchers.map(voucher => (
              <div key={voucher.id} className={`voucher ${voucher.redeemed ? 'redeemed' : 'available'}`}>
                <div className="voucher-content">
                  <p className="voucher-description">{program.description || 'No description'}</p>
                  <p className="voucher-id">Voucher ID: {voucher.id}</p>
                  <p className="voucher-status">Status: {voucher.redeemed ? 'Redeemed' : 'Available'}</p>
                  {voucher.redeemed && <p className="voucher-redeemed-date">Redeemed at: {formatDate(voucher.redeemed_at)}</p>}
                  <p className="voucher-created-date">Created at: {formatDate(voucher.created_at)}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="no-vouchers">No vouchers available</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoyaltyCard;