import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { registerPWA } from '../utils/auth';
import '../styles/RegisterPWA.css';

function RegisterPWA() {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    password: '',
    fullName: '',
    marketingConsent: false
  });
  const [error, setError] = useState('');
  const { subUrl } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await registerPWA(
        formData.email,
        formData.phone,
        formData.password,
        formData.fullName,
        formData.marketingConsent,
        subUrl
      );
      console.log('Registration successful', response);
      localStorage.setItem('token', response.token);
      navigate(`/vendor/${subUrl}`);
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <div className="register-pwa">
      <h2>Register</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          placeholder="Full Name"
          required
        />
        <label>
          <input
            type="checkbox"
            name="marketingConsent"
            checked={formData.marketingConsent}
            onChange={handleChange}
          />
          I agree to receive marketing communications
        </label>
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default RegisterPWA;