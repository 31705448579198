import React, { useState, useEffect, useCallback, useRef  } from 'react';
import debounce from 'lodash.debounce';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken, isTokenValid, logout } from '../utils/auth';
import '../styles/VendorPublicPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BookingSection from './BookingSection';
import LoginModal from './LoginModal';

const carouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

function VendorPublicPage() {
  const [activeTab, setActiveTab] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [standaloneVouchers, setStandaloneVouchers] = useState([]);
  const { subUrl } = useParams();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const [slides, setSlides] = useState([]);
  const [specials, setSpecials] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [isStandalone, setIsStandalone] = useState(false);
  const profileMenuRef = useRef(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [enrollingProgramId, setEnrollingProgramId] = useState(null);


  const handleAboutClick = useCallback((program) => {
    setSelectedProgram(program);
    setShowAboutModal(true);
  }, []);
  
  useEffect(() => {
    const handleDeepLink = () => {
      const params = new URLSearchParams(window.location.search);
      const tab = params.get('tab');
  
      if (tab) {
        setActiveTab(tab);
        // Remove the tab parameter from the URL after handling
        const newUrl = new URL(window.location);
        newUrl.searchParams.delete('tab');
        window.history.replaceState({}, '', newUrl);
      }
    };
  
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
        const { tab, vendorSubUrl } = event.data;
        if (tab) {
          setActiveTab(tab);
        }
        console.log('Notification clicked, vendorSubUrl:', vendorSubUrl);
        // You can add more logic here if needed, e.g., navigate to a specific page
      }
    };
  
    handleDeepLink();
  
    // Add event listener for 'message' events from the service worker
    navigator.serviceWorker.addEventListener('message', handleMessage);
  
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, []);
  
  // Keep the about modal logic separate
  useEffect(() => {
    const handleAboutModalUrl = () => {
      const currentUrl = new URL(window.location);
      if (showAboutModal && selectedProgram) {
        currentUrl.searchParams.set('programId', selectedProgram.id);
      } else {
        currentUrl.searchParams.delete('programId');
      }
      window.history.replaceState({}, '', currentUrl);
    };
  
    handleAboutModalUrl();
  }, [showAboutModal, selectedProgram]);


  // const updateManifest = useCallback(
  //   debounce(() => {
  //     const link = document.querySelector("link[rel='manifest']");
  //     const newHref = `${process.env.REACT_APP_API_URL}/manifest.json?subUrl=${subUrl}`;
  //     console.log('Updating manifest to:', newHref);
      
  //     if (link) {
  //       if (link.href !== newHref) {
  //         link.href = newHref;
  //       }
  //     } else {
  //       const newLink = document.createElement('link');
  //       newLink.rel = 'manifest';
  //       newLink.href = newHref;
  //       document.head.appendChild(newLink);
  //     }
  //   }, 500),
  //   [subUrl]
  // );

  const updateManifest = useCallback(
    debounce(() => {
      try {
        const link = document.querySelector("link[rel='manifest']");
        const newHref = `${process.env.REACT_APP_API_URL}/manifest.json?subUrl=${subUrl}`;
        console.log('Updating manifest to:', newHref);
        
        if (link) {
          if (link.href !== newHref) {
            link.href = newHref;
          }
        } else {
          const newLink = document.createElement('link');
          newLink.rel = 'manifest';
          newLink.href = newHref;
          document.head.appendChild(newLink);
        }
      } catch (error) {
        console.error('Error updating manifest:', error);
      }
    }, 500),
    [subUrl]
  );
  
  useEffect(() => {
    updateManifest();
    
    // Add an event listener for 'focus' to update manifest when tab regains focus
    window.addEventListener('focus', updateManifest);
    
    return () => {
      window.removeEventListener('focus', updateManifest);
    };
  }, [updateManifest]);

  
  const fetchVendorInfo = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}`);
      setVendor(response.data);
    } catch (error) {
      console.error('Error fetching vendor info:', error);
    }
  }, [subUrl, setVendor]);
  
  const fetchSpecials = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/specials`);
      setSpecials(response.data.specials);
    } catch (error) {
      console.error('Error fetching specials:', error);
    }
  }, [subUrl]);
  
  const fetchMenus = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/menus`);
      setMenus(response.data);
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  }, [subUrl]);
  
  const fetchPrograms = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/programs`);
      setPrograms(response.data);
    } catch (error) {
      console.error('Error fetching programs:', error);
      setError('Failed to fetch programs. Please try again later.');
    }
  }, [subUrl]);
  
  const fetchEnrolledPrograms = useCallback(async () => {
    if (!isTokenValid()) {
      setEnrolledPrograms([]);
      setStandaloneVouchers([]);
      return;
    }
    try {
      const response = await axios.get('/api/customers/programs');
      console.log('Fetched enrolled programs:', response.data);
      
      const filteredPrograms = response.data.enrolledPrograms.filter(p => p.vendor_sub_url === subUrl);
      setEnrolledPrograms(filteredPrograms || []);
  
      const filteredVouchers = response.data.standaloneVouchers.filter(v => v.vendor_sub_url === subUrl);
      setStandaloneVouchers(filteredVouchers || []);
    } catch (error) {
      console.error('Error fetching enrolled programs:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
      }
      setEnrolledPrograms([]);
      setStandaloneVouchers([]);
    }
  }, [subUrl]);
  
  const fetchSlides = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/public-slides`);
      setSlides(response.data);
    } catch (error) {
      console.error('Error fetching slides:', error);
      setError('Failed to fetch slides. Please try again later.');
    }
  }, [subUrl]);
  
  const debouncedFetchData = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        await Promise.all([
          fetchVendorInfo(),
          fetchPrograms(),
          fetchEnrolledPrograms(),
          fetchSlides(),
          fetchSpecials(),
          fetchMenus()
        ]);
        setError(null);
      } catch (err) {
        setError('Failed to load vendor data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [fetchVendorInfo, fetchPrograms, fetchEnrolledPrograms, fetchSlides, fetchSpecials, fetchMenus]
  );
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenValid()) {
      setAuthToken(token);
      setIsLoggedIn(true);
    } else {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
    }
    
    const checkStandalone = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      setIsInstallable(!isStandalone);
    };
  
    checkStandalone();
    debouncedFetchData();
  
    const handleBeforeInstallPrompt = (e) => {
      console.log('Before install prompt event fired');
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };
  
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) && !event.target.closest('.profile-button')) {
        setShowProfileMenu(false);
      }
    };
  
    const handleAppInstalled = () => {
      console.log('App installed');
      setIsInstallable(false);
      setDeferredPrompt(null);
    };
  
    // window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    });
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('appinstalled', handleAppInstalled);
  
    // Check for display-mode changes
    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    mediaQuery.addListener(checkStandalone);
  
    console.log('Initial isInstallable:', isInstallable);
    console.log('Initial deferredPrompt:', deferredPrompt);
  
    return () => {
      debouncedFetchData.cancel();
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('appinstalled', handleAppInstalled);
      mediaQuery.removeListener(checkStandalone);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Remove dependencies to ensure this effect runs only once

  
  const handleProfileButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };
  
  const handleMenuItemClick = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProfileMenu(false);
    action();
  };
  
  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsLoggedIn(false);
    setShowProfileMenu(false);
    window.location.reload();
  };
  
  const handleInstallClick = useCallback(() => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setIsInstallable(false);
        }
        setDeferredPrompt(null);
      });
    } else {
      console.log('Installation prompt not available');
    }
  }, [deferredPrompt]);  
  

  // const promptPWA = () => {
  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the install prompt');
  //       } else {
  //         console.log('User dismissed the install prompt');
  //       }
  //       setDeferredPrompt(null);
  //     });
  //   }
  // };


  const renderWelcomeScreen = () => (
    <div className="welcome-screen">
      <h1>Welcome to {vendor?.business_name}</h1>
      {slides.length > 0 ? (
        <Carousel
          responsive={carouselResponsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          showDots={true}
          arrows={true}
          className="vendor-carousel"
          containerClass="carousel-container"
          itemClass="carousel-item"
        >
          {Array.isArray(slides) && slides.map((slide, index) => (
            <img 
              key={index} 
              src={slide.image_url} 
              alt={`Slide ${index + 1}`} 
              className="carousel-image" 
            />
          ))}
        </Carousel>
      ) : (
        <img src={vendor?.cover_url} alt="Cover" className="cover-image" />
      )}
      <div className="welcome-screen-buttons">
          {isInstallable && !window.matchMedia('(display-mode: standalone)').matches && (
            <button className="install-app-btn" onClick={handleInstallClick}>Install App</button>
          )}
          {!isLoggedIn && (
            <button className="login-btn" onClick={() => setShowLoginModal(true)}>Login</button>
          )}
        </div>
    </div>
  );

  const renderProfileMenu = () => (
    <div ref={profileMenuRef} className={`profile-menu ${showProfileMenu ? 'active' : 'hidden'}`}>
      {isLoggedIn ? (
        <button onClick={handleMenuItemClick(handleLogout)}>Logout</button>
      ) : (
        <>
          <button onClick={handleMenuItemClick(() => setShowLoginModal(true))}>Login</button>
          <button onClick={handleMenuItemClick(() => navigate(`/register/${subUrl}`))}>Register</button>
        </>
      )}
      <button onClick={handleMenuItemClick(() => navigate('/push-notifications'))}>Manage Notifications</button>
    </div>
  );



  const enrollInProgram = useCallback(async (programId) => {
    const token = localStorage.getItem('token');
    if (!token || !isTokenValid()) {
      setEnrollingProgramId(programId);
      setShowLoginModal(true);
      return;
    }
    try {
      const response = await axios.post('/api/customers/enroll', { programId });
      console.log('Enrollment response:', response.data);
      setEnrolledPrograms(prev => [...prev, response.data.enrolledProgram]);
      alert('Successfully enrolled in the program!');
    } catch (error) {
      console.error('Error enrolling in program:', error);
      if (error.response && error.response.status === 401) {
        alert('Your session has expired. Please log in again.');
        navigate('/login');
      } else if (error.response && error.response.status === 400) {
        alert(error.response.data.error || 'You are already enrolled in this program.');
      } else {
        alert('Failed to enroll in the program. Please try again.');
      }
    }
  }, [navigate]);

  const handleLoginSuccess = (user) => {
    setIsLoggedIn(true);
    setShowLoginModal(false);
    if (enrollingProgramId) {
      enrollInProgram(enrollingProgramId);
      setEnrollingProgramId(null);
    }
    fetchEnrolledPrograms();
    fetchPrograms();
  };





  const renderTabContent = () => {
    if (!activeTab) {
      if (!isLoggedIn) {
        return renderWelcomeScreen();
      } else {
        return (
          <div className="welcome-content">
            <h2>Welcome to {vendor?.business_name || 'Our Business'}</h2>
            <p>You can now view your loyalty cards and make bookings!</p>
            {slides.length > 0 && (
              <Carousel
                responsive={carouselResponsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                showDots={true}
                arrows={true}
                className="vendor-carousel"
                containerClass="carousel-container"
                itemClass="carousel-item"
              >
                {slides.map((slide, index) => (
                  <img 
                    key={index} 
                    src={slide.image_url} 
                    alt={`Slide ${index + 1}`} 
                    className="carousel-image" 
                  />
                ))}
              </Carousel>
            )}
          </div>
        );
      }
    }  

    switch (activeTab) {
      case 'loyalty':
        return (
          <div className="loyalty-tab">
            <h2>Your Loyalty Programs</h2>
            {Array.isArray(enrolledPrograms) && enrolledPrograms.map(program => (
              <div key={program.id} className="program-card enrolled">
                <h3>{program.name}</h3>
                <p>Stamps required: {program.stamps_required}</p>
                <Link to={`/loyalty-card/${program.id}`} className="view-card-link">
                  View Full Loyalty Card
                </Link>
              </div>
            ))}
      
            <h2>Available Programs</h2>
            {Array.isArray(programs) && programs
              .filter(program => !enrolledPrograms.some(ep => ep.id === program.id))
              .map(program => (
                <div key={program.id} className="program-card">
                  <h3>{program.name}</h3>
                  <p>Stamps required: {program.stamps_required}</p>
                  <button className="enroll-btn" onClick={() => enrollInProgram(program.id)}>
                    Enroll
                  </button>
                  <button className="about-btn" onClick={() => handleAboutClick(program)}>
                    About
                  </button>
                </div>
              ))
            }
            <div className="vouchers-section">
              <h3>All Vouchers</h3>
              <h4>Program Vouchers</h4>
            {enrolledPrograms.flatMap(program => 
              (program.vouchers || [])
                .sort((a, b) => {
                  const aRedeemed = !!a.redeemed_at;
                  const bRedeemed = !!b.redeemed_at;
                  return aRedeemed === bRedeemed ? 0 : aRedeemed ? 1 : -1;
                })
                .map(voucher => (
                  <div key={`program-${program.id}-${voucher.id}`} className={`voucher ${voucher.redeemed_at ? 'redeemed' : 'unredeemed'}`}>
                    <p>{program.name}: {program.description || 'No description'}</p>
                    <p>Status: {voucher.redeemed_at ? 'Redeemed' : 'Available'}</p>
                    <p>Created: {new Date(voucher.created_at).toLocaleDateString()}</p>
                    {voucher.redeemed_at && <p>Redeemed: {new Date(voucher.redeemed_at).toLocaleDateString()}</p>}
                  </div>
                ))
            )}
              
              <h4>Standalone Vouchers</h4>
              {standaloneVouchers.map(voucher => (
              <div key={`standalone-${voucher.id}`} className={`voucher ${voucher.redeemed_at ? 'redeemed' : 'unredeemed'}`}>
                <p>{voucher.description || `Value: ${voucher.value}`}</p>
                <p>Status: {voucher.redeemed_at ? 'Redeemed' : 'Available'}</p>  {/* Show Redeemed/Available */}
                <p>Created: {new Date(voucher.created_at).toLocaleDateString()}</p>
                {voucher.redeemed_at && <p>Redeemed: {new Date(voucher.redeemed_at).toLocaleDateString()}</p>}
              </div>
            )).sort((a, b) => {
              const aRedeemed = a.props.children[1].props.children === 'Status: Redeemed';
              const bRedeemed = b.props.children[1].props.children === 'Status: Redeemed';
              if (aRedeemed === bRedeemed) return 0;
              return aRedeemed ? 1 : -1;
            })}
            </div>
          </div>
        );
        case 'menu':
        return (
          <div className="menu-tab">
            <h2>Our Menus</h2>
            {menus.map(menu => (
              <div key={menu.id} className="menu-item">
                {menu.thumbnail_url && (
                  <img src={menu.thumbnail_url} alt="Menu thumbnail" className="menu-thumbnail" />
                )}
                {menu.pdf_url && (
                  <a href={menu.pdf_url} target="_blank" rel="noopener noreferrer" className="view-menu-btn">
                    View PDF Menu
                  </a>
                )}
                {menu.weblink && (
                  <a href={menu.weblink} target="_blank" rel="noopener noreferrer" className="view-menu-btn">
                    View Online Menu
                  </a>
                )}
              </div>
            ))}
          </div>
        );
        case 'specials':
  return (
    <div className="specials-tab">
      <h2>Our Specials</h2>
      {specials.map(special => (
        <div key={special.id} className="special-item">
          <h3>{special.name}</h3>
          <p>{special.description}</p>
          {special.value && <p>Value: R{special.value}</p>}
          {special.image_url && (
            <div className="special-image-container">
              <img src={special.image_url} alt={special.name} className="special-image" />
            </div>
          )}
          {special.weblink && (
            <a href={special.weblink} target="_blank" rel="noopener noreferrer" className="special-link">
              Learn More
            </a>
          )}
        </div>
      ))}
    </div>
  );
      case 'booking':
        return <BookingSection vendorId={vendor.id} />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!vendor) return null;

  return (
    <div className="vendor-public-page">
    <header>
      <div className="logo-title-container">
        <img src={vendor.logo_url} alt="Vendor Logo" className="vendor-logo" />
        <h1>{vendor.business_name}</h1>
      </div>
      <button onClick={handleProfileButtonClick} className="profile-button">
      <FontAwesomeIcon icon="user" />
    </button>
    </header>

    {renderProfileMenu()}

      {vendor.cover_url && (
        <div className="cover-image-container">
          <img src={vendor.cover_url} alt="Cover" className="cover-image" />
        </div>
      )}

      <div className="vendor-info">
        <p><FontAwesomeIcon icon="map-marker-alt" /> {vendor.address}</p>
        <p><FontAwesomeIcon icon="phone" /> {vendor.phone}</p>
      </div>

      <nav className="tab-navigation">
        <button onClick={() => setActiveTab('loyalty')} className={activeTab === 'loyalty' ? 'active' : ''}>Loyalty</button>
        <button onClick={() => setActiveTab('menu')} className={activeTab === 'menu' ? 'active' : ''}>Menu</button>
        <button onClick={() => setActiveTab('specials')} className={activeTab === 'specials' ? 'active' : ''}>Specials</button>
        <button onClick={() => setActiveTab('booking')} className={activeTab === 'booking' ? 'active' : ''}>Booking</button>
      </nav>

      <div className="tab-content">
      {!isLoggedIn && !activeTab ? renderWelcomeScreen() : renderTabContent()}
    </div>

    {showLoginModal && (
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        onLoginSuccess={handleLoginSuccess}
        vendorSubUrl={subUrl}
      />
    )}

    {showAboutModal && selectedProgram && (
          <div className="about-modal">
            <div className="about-modal-content">
              <h3>About {selectedProgram.name}</h3>
              <p>{selectedProgram.about || 'No additional information available.'}</p>
              <button onClick={() => setShowAboutModal(false)}>Close</button>
            </div>
          </div>
        )}

      {vendor.whatsapp_number && (
        <a 
          href={`https://wa.me/${vendor.whatsapp_number}`} 
          className="whatsapp-button"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          <span className="whatsapp-text"> Chat with us</span>
        </a>
      )}
    </div>
  );
}

export default VendorPublicPage;